<template>
  <div>
    <div
      v-if="isError"
    >
      <component-not-found
        title="User List"
        :button-link="{ name: 'user-list' }"
      />
    </div>

    <div v-else>
      <div v-if="!isLoading">
        <b-row class="text-nowrap">
          <div class="ml-auto mr-1 mb-1">
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom
              icon="LAuditIconUpdated"
              :title="$t('Audit')"
              size="36"
              class="cursor-pointer"
              @click="gotoNewPage({ name: 'user-audit' }, $event)"
            />
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom
              icon="LTrashIconUpdated"
              :title="$t('Delete')"
              size="36"
              class="cursor-pointer ml-1"
              @click="setInActive()"
            />
            <feather-icon
              v-if="organizationRequiresEditConfirmation"
              v-b-tooltip.noninteractive.hover.bottom
              :title="$t('Edit')"
              :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
              size="36"
              class="ml-1 cursor-pointer"
              @click="isEditAble()"
            />
          </div>
        </b-row>
        <user-name />
        <user-form
          ref="user-form"
          :is-update-form="true"
          :is-edit="isEdit"
        />
      </div>
      <skeleton v-else />
    </div>
  </div>
</template>

<script>
import { BRow, VBTooltip } from 'bootstrap-vue'
import userName from '@/views/settings/users/components/UserName.vue'
import store from '@/store'
import config from '../userConfig'
import UserForm from '../components/UserForm.vue'
import Skeleton from '../components/Skeleton.vue'

export default {
  name: 'UserCreate',
  components: {
    BRow,
    userName,
    UserForm,
    Skeleton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['user-form']) {
      this.$refs['user-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    user() {
      return this.$store.state[this.MODULE_NAME].user
    },
  },
  created() {
    this.refetchData()
  },
  methods: {
    isEditAble() {
      return this.isEdit = !this.isEdit
    },
    setInActive() {
      const sendData = {
        ...this.user,
        warehouse_id: this.user.warehouse?.id,
        setting_notifications_id: this.user.setting_notifications_id.id,
      }
      this.confirmNotification(this, sendData, `${this.MODULE_NAME}/setInActive`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.$router.push({ name: 'user-list' })
        })
    },
    refetchData() {
      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(res => {
        const { data } = res.data

        const user = this.mappingFields(Object.keys(this.fields), data, {
          role_id: data.role,
          warehouse_id: data.warehouse,
          id: data.id,
        })
        this.$store.commit(`${this.MODULE_NAME}/SET_USER_FORM`, user)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_USER_FORM_CLONE`, user)
      }).catch(err => {
        this.isError = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
  setup() {
    const MODULE_NAME = 'users'
    const MODULE_NAME_CLONE = 'cloneData'

    const { fields } = config()
    return {
      MODULE_NAME,
      fields,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
