<template>
  <div>
    <div class="d-flex justify-content-center">
      <b-skeleton
        height="30px"
        width="20%"
        style="border-radius: 5px"
      />
    </div>
    <b-row>
      <b-col
        v-for="field in Object.keys(fields)"
        :key="field"
        md="6"
      >
        <span class="inputLabel">{{ fields[field].label }}</span>
        <b-skeleton
          height="40px"
          style="border-radius: 5px"
        />
      </b-col>
    </b-row>
  </div>

</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'
import config from '../userConfig'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>
