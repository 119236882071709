<template>
  <p class="text-center font-weight-bolder font-large-1">
    {{ getUserName() }}
  </p>
</template>

<script>

export default {
  name: 'UserName',
  props: {
    title: {
      type: String,
      require: true,
      default: () => ' ',
    },
  },
  computed: {
    user() {
      return this.$store.state[this.MODULE_NAME].user
    },
  },
  methods: {
    getUserName() {
      return `${this.user.firstname} ${this.user.lastname} ${this.$t(this.$props.title)}`
    },
  },
  setup() {
    const MODULE_NAME = 'users'
    return {
      MODULE_NAME,
    }
  },

}
</script>
